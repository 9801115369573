import { initializeApp } from "firebase/app";
import {
  getFirestore,
  getDocs,
  collection,
//   onSnapshot,
//   addDoc,
//   deleteDoc,
//   doc,
  query,
  where,
//   orderBy,
//   serverTimestamp,
//   updateDoc,
} from "firebase/firestore";

// import config from "config";

initializeApp({
    apiKey: "AIzaSyBGXu-XbcnGO473bUv9wN47rCuDG0OoAxI",
    authDomain: "careful-rock-295810.firebaseapp.com",
    databaseURL: "https://careful-rock-295810-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "careful-rock-295810",
    storageBucket: "careful-rock-295810.appspot.com",
    messagingSenderId: "1079844082762",
    appId: "1:1079844082762:web:11b3122565708b567cc2b3",
    measurementId: "G-RBWB7E4BLZ"
  });
// init services
const db = getFirestore();
export default class Produckts {
  constructor() {
    // collection ref
    this.collection = collection(db, "eoltabelle");
  }
  async #getDataByQuery(q) {
    // get collection data
    return getDocs(q)
      .then((snapshot) => {
        // console.log(snapshot.docs)
        let produckts = [];
        snapshot.docs.forEach((doc) => {
          produckts.push({ ...doc.data(), id: doc.id });
        });
        return produckts;
      })
      .catch((err) => {
        console.log(err.message);
      });
  }
  async getProducktBySetnumber(setnumber = 10255) {
    // queries
    const q = query(this.collection, where("setnumber", "==", setnumber));
    return await this.#getDataByQuery(q);
  }
  async getAllWebAktivProduckt() {
    // queries
    const q = query(this.collection, where("webaktiv", "==", true));
    return await this.#getDataByQuery(q);
  }
  async getAllProduckt() {
    // queries
    const q = query(this.collection);
    return await this.#getDataByQuery(q);
  }
}