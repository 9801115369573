import Produckts from "../db.js";
const produckts = new Produckts();

export default {
  state: {
    development: process.env.NODE_ENV === "development" ? false : true,
    gauge: [],
    products: [],
    filteredProducts: [],
    productStatuses: [
      { text: "Frischling", value: 1 },
      { text: "Entspannt", value: 2 },
      { text: "In Kürze", value: 3 },
      { text: "Zielgerade", value: 4 },
      { text: "Frührente", value: 5 },
      { text: "EOL", value: 6 },
    ],
    sortItems: [
      {
        name: "bestes Angebot (Prozentual)",
        value: "discount",
        isDesc: true,
      },
      { name: "größte Ersparnis zur UVP", value: "difference", isDesc: true },
      // { name: "POV Differenz", value: "difference_", isDesc: true },
      // { name: "Produkttitel (A-Z)", value: "name", isDesc: true },
      // { name: "Produkttitel (Z-A)", value: "name", isDesc: false },
      // { name: "Themenwelt (A-Z)", value: "theme", isDesc: true },
      // { name: "Themenwelt (Z-A)", value: "theme", isDesc: false },
      {
        name: "Erscheinungsjahr (neueste zuerst)",
        value: "year",
        isDesc: true,
      },
      {
        name: "Erscheinungsjahr (älteste zuerst)",
        value: "year",
        isDesc: false,
      },
      {
        name: "EOL (neueste zuerst)",
        value: "eol",
        isDesc: true,
      },
      {
        name: "EOL (älteste zuerst)",
        value: "eol",
        isDesc: false,
      },
      {
        name: "UVP-Preis (Aufsteigend)",
        value: "retailpricebrutto",
        isDesc: false,
      },
      {
        name: "UVP-Preis (Absteigend)",
        value: "retailpricebrutto",
        isDesc: true,
      },
      // "UVP-Preis (Aufsteigend)",
      // "UVP-Preis (Absteigend)",
    ],
    merchants: [
      { merchant: "Alternate", merchant_id: 1 },
      { merchant: "Alza", merchant_id: 2 },
      { merchant: "amazon", merchant_id: 3 },
      { merchant: "Brick-X-Treme", merchant_id: 8 },
      { merchant: "Coolshop", merchant_id: 12 },
      { merchant: "Galaxus.de", merchant_id: 15 },
      { merchant: "Galeria.de", merchant_id: 16 },
      { merchant: "JB Spielwaren", merchant_id: 18 },
      { merchant: "kaufland.de", merchant_id: 19 },
      { merchant: "LEGO", merchant_id: 21 },
      { merchant: "OTTO", merchant_id: 24 },
      { merchant: "Proshop", merchant_id: 25 },
      { merchant: "SpieleMax", merchant_id: 30 },
      { merchant: "Taschengelddieb", merchant_id: 35 },
      { merchant: "Zavvi.de", merchant_id: 41 },
    ],
    specialty: [
      {
        id: 1,
        name: "Exklusiv",
        value: "LEGO exclusive",
        key: "exclusives",
        color: "blue",
      },
      {
        id: 2,
        name: "Seltene Sets",
        value: "Retail - limited",
        key: "exclusives",
        color: "indigo",
      },
      {
        id: 3,
        name: "Kurze Laufzeit",
        value: "shortTerm",
        key: "shortTerm",
        color: "",
      },
      {
        id: 4,
        name: "Exklusive Minifiguren",
        value: "uniqueMinifigs",
        key: "uniqueMinifigs",
        color: "orange",
      },
      {
        id: 5,
        name: "Starke Lizenz",
        value: "specialty1",
        key: "specialty1",
        color: "red darken-2",
      },
      {
        id: 6,
        name: "Ikonisch",
        value: "specialty3",
        key: "specialty3",
        color: "",
      },
      {
        id: 7,
        name: "Sammler",
        value: "specialty2",
        key: "specialty2",
        color: "",
      },
      {
        id: 8,
        name: "UVP Erhöhung",
        value: "specialty4",
        key: "specialty4",
        color: "",
      },
      {
        id: 9,
        name: "specialty5",
        value: "specialty5",
        key: "specialty5",
        color: "",
      },
      {
        id: 20,
        name: "specialty5",
        value: "specialty6",
        key: "specialty6",
        color: "",
      },
    ],
  },
  actions: {
    async fetchProducts({ commit }, limit) {
      limit = limit || 1000;
      let products = null;
      await produckts.getAllWebAktivProduckt().then((respons) => {
        products = respons;
      });
      commit("updateProducts", products.splice(0, limit));
    },
  },
  mutations: {
    updateProducts(state, products) {
      products.forEach((product) => {
        product.status = state.productStatuses.filter(
          (status) => status.value == product.gauge
        )[0];
        product.year = product.year.match(/(\d{4}$)/gm)[0];
        if (product.deep_link) {
          product.merchants = product.deep_link;
          delete product.deep_link;
          product.merchants.map((record) => {
            record.price = record.price || 0;
            record.fremdl =
              record.MerchantName && record.MerchantName != "Amazon.de"
                ? true
                : false;
            if (record.price < product.retailpricebrutto)
              record.percent =
                Math.round(
                  (record.price / product.retailpricebrutto - 1) * -100
                ) || 0;
          });
          product.merchants = product.merchants.sort(
            (a, b) => a.price - b.price
          );
          product.merchants_ = {
            availability: product.merchants.filter(
              (merchant) => merchant.availability === true
            ),
            notAvailability: product.merchants.filter(
              (merchant) => merchant.availability === false
            ),
          };
          product.discount = product.merchants_.availability
            ? product.merchants_.availability[0]?.percent
            : 0;
          product.difference = product.merchants_.availability
            ? product.retailpricebrutto -
              product.merchants_.availability[0]?.price
            : null;
        }
        product.discount = product.discount || 0;
        product.difference = product.difference || 0
      });
      state.products = products;
      this.commit("filteredProducts", []);
    },
    filteredProducts(state) {
      state.filteredProducts = state.products.slice(0, 18);
    },
  },
  getters: {
    allProducts(state) {
      return state.products;
    },
    productsTheme(state) {
      return state.products
        .filter((item) => item.theme !== "")
        .map((record) => record.theme.trim())
        .filter(
          (recordTheme, index, self) => self.indexOf(recordTheme) === index
        );
    },
    productStatuses(state) {
      return state.productStatuses;
    },
    productSortItems(state) {
      return state.sortItems;
    },
    productsFiltered(state) {
      // console.log(this.$store.state.productfeed.products.length)
      // if (conditions.length > 0) {
      //   this.$store.state.productfeed.products =  this.$store.state.productfeed.products.filter((ls) => {
      //     return conditions.every((condition) => {
      //       return condition(ls);
      //     });
      //   });
      // }
      return state.filteredProducts;
    },
  },
};
