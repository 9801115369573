import Vue from 'vue'
import Vuex from 'vuex'
import productfeed from './modules/productfeed'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        productfeed
    }
})

