<template>
  <v-app>
    <v-app-bar elevation="1" app fixed>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title class="text-uppercase grey--text">
        <span class="font-weight-light">Der </span>
        <span>Spielwaren Investor</span>
      </v-toolbar-title>
      <v-spacer />
      <v-btn icon @click.stop="rightDrawer = !rightDrawer">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main>
      <v-container>
        <router-view />
      </v-container>
    </v-main>
    <v-footer dark padless>
      <v-card
        flat
        tile
        width="100%"
        class="indigo lighten-1 white--text text-center"
      >
        <v-card-text class="white--text pa-6">
          LEGO® ist eine Marke der LEGO Gruppe, durch die diese Webseite weder
          gesponsert noch autorisiert oder unterstützt wird.
        </v-card-text>
        <v-divider></v-divider>
        <v-row no-gutters>
          <v-col cols="12" md="4" class="mb-3">
            <v-card-text class="white--text text-left">
              &copy; {{ new Date().getFullYear() }} —
              <strong>Der Spielwaren Investor</strong>
            </v-card-text>
          </v-col>
          <v-col cols="12" md="4" class="mb-3">
            <v-card-text>
              <v-btn
                v-for="reference in references"
                :key="reference.icon"
                class="mx-4 white--text"
                :href="reference.link"
                target="_blank"
                icon
              >
                <v-icon size="24px">
                  {{ reference.icon }}
                </v-icon>
              </v-btn>
            </v-card-text>
          </v-col>
          <v-col cols="12" md="4" class="mb-3">
            <v-card-text>
              <a 
                  class="white--text"
                  href="https://spielwaren-investor.com/impressum-datenschutz/"
                  target="_blank"
              >
                Impressum / Datenschutzerklärung
              </a>
            </v-card-text>
          </v-col>
        </v-row>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>


export default {
  name: "App",

  data: () => ({
      drawer: null,
        items: [
          { title: 'Home', icon: 'mdi-view-dashboard' },
          { title: 'About', icon: 'mdi-forum' },
        ],
    fixed: true,

    references: [
      {icon: "mdi-wordpress", link: 'https://spielwaren-investor.com/'},
      {icon: "mdi-instagram", link: 'https://www.instagram.com/der_spielwaren_investor/?hl=de'},
      {icon: "mdi-spotify", link: 'https://open.spotify.com/show/7nwKLoptCS7YFNKpDkti3p'},
      {icon: "mdi-deezer", link: 'https://www.deezer.com/de/show/54919?utm_source=deezer&utm_content=show-54919&utm_term=958213022_1540464765&utm_medium=web'},
      {icon: "mdi-youtube", link: 'https://www.youtube.com/channel/UCU6B66hnds2qgdewJPly_yg'},
      {icon: "mdi-podcast", link: 'https://podcasts.apple.com/de/podcast/der-spielwaren-investor-spielend-reale-rendite/id1277803720'},
      {icon: "mdi-telegram", link: 'https://t.me/LEGOangebote'},
    ]
  }),
};
</script>
